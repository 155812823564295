import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import { IPaginationResponse } from "@utils/models/PaginationCommonResponse";

// type Optional<T> = Partial<IPaginationResponse<T | null>>;
type Optional<T> = Partial<IPaginationResponse<T | null>>;

export function usePaginatedQuery<T>(
  URL: string[],
  request: QueryFunction<Optional<T>>,
  options?: Omit<
    UseQueryOptions<Optional<T>, any, any, any>,
    "queryKey" | "queryFn" | "initialData"
  > & { mockContentLength?: number; initialData?: () => Optional<T> }
) {
  const { mockContentLength, ...rest } = options ?? { mockContentLength: 10 };
  return useQuery<Optional<T>>(URL, request, {
    ...rest,
    refetchOnWindowFocus: false,
    initialData: () => ({
      content: new Array(mockContentLength).fill(null),
    }),
  });
}
