import { AnimatedBox } from "components/AnimatedBox";
import { motion } from "framer-motion";
import { useEffect } from "react";

export const Skeleton = ({
  width,
  height,
  borderRadius,
  duration,
  isLoading = true,
  children,
  ...props
}: any) => {
  const animationVariants = {
    start: {
      opacity: 1,
      backgroundColor: "#F2F2F2",
    },
    end: {
      opacity: 0,
      backgroundColor: "#CCCCCC",
    },
  };

  const animationTransition = {
    duration: 2,
    repeatType: "loop",
    repeat: Infinity,
    // ease: "backInOut",
    ease: [0.17, 0.67, 0.54, 0],
  };

  return isLoading ? (
    <AnimatedBox
      variants={animationVariants}
      initial="start"
      animate="end"
      transition={animationTransition}
      {...props}
    ></AnimatedBox>
  ) : (
    children
  );
};
