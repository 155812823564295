import { BarChart, Widget, WidgetInstanceProps } from "@components/";
import { VStack } from "native-base";
import { FC } from "react";

export interface GrowthWidgetProps extends WidgetInstanceProps<any> {}

export const GrowthWidget: FC<GrowthWidgetProps> = ({ ...props }) => {
  const { followers, posts } = { followers: 2313, posts: 123 };

  return (
    <Widget onClick={() => {}} {...props}>
      <VStack space="2">
        <BarChart />
      </VStack>
    </Widget>
  );
};
