import { generateBlobFromFile } from "@utils/hooks";
import { IFileDef } from "@utils/models/FileItem";

export const createFileDefInstances = async (files: File[]) => {
  const fileWithBlob = files.map(async (file: File) => {
    const blob = await generateBlobFromFile(file);
    const newFile: IFileDef = {
      file,
      blob,
    };
    return newFile;
  });
  return await Promise.all(fileWithBlob);
};
