import {
  CommonSection,
  EmptyButton,
  RadioField,
  SectionHeader,
  Table,
  TextField,
  TextAreaField,
  SwitchButton,
} from "@components/";
import {
  IconTrash,
  IconPlus,
  IconKey,
  IconEdit,
  IconPassword,
} from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/react-table";
import { useIsMobile, withFormFieldProps } from "@utils/hooks";
import { withFormField } from "@utils/hoc";
import {
  VStack,
  HStack,
  Button,
  Image,
  Text,
  Switch,
  Checkbox,
  Stack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@utils/hooks";

export interface NotificationSettingsFormProps extends InterfaceBoxProps {}

const NCheckbox = (props: any) => <Checkbox {...props} />;
const CheckboxFieldForm = withFormField(NCheckbox, {
  formContainerProps: {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    space: 2,
    // maxW: "250px",
  },
});

export const NotificationSettingsForm: FC<NotificationSettingsFormProps> = ({
  ...props
}) => {
  const { control } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation({ keyPrefix: "notifications" });
  const isMobile = useIsMobile();
  return (
    <CommonSection
      title={t("forms.settings.title")}
      containerProps={{ minH: "20px" }}
      nextButton={t("forms.settings.fields.submitButton")}
      nextButtonProps={{ variant: "outline", bg: "transparent" }}
    >
      <VStack>
        <Stack
          direction={isMobile ? "column" : "row"}
          // space="2"
          justifyContent="space-between"
        >
          <CheckboxFieldForm
            form={{ control, name: "created" }}
            label={t("forms.settings.fields.created.label")}
          />
          <CheckboxFieldForm
            form={{ control, name: "paySubscription" }}
            label={t("forms.settings.fields.subscription.label")}
          />
          <CheckboxFieldForm
            form={{ control, name: "cancelSubscription" }}
            label={t("forms.settings.fields.cancel.label")}
          />
        </Stack>
      </VStack>
    </CommonSection>
  );
};
