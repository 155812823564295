import React, { FC, InputHTMLAttributes, useEffect, useRef } from "react";
import {
  FormControl,
  IInputProps,
  Input,
  TextArea,
  Text,
  Stack,
  WarningOutlineIcon,
} from "native-base";

export interface TextFieldProps extends IInputProps {
  containerProps?: any;
}

export const TextField: FC<TextFieldProps> = ({
  label,
  helperText,
  errorMessage,
  containerProps,
  name,
  value,
  onChange,
  ...props
}) => {
  const ref = useRef();

  // useEffect(() => {
  //   const field: any = ref?.current;
  //   if (field) {
  //     field.setAttribute("name", name);
  //   }
  // }, [ref, name]);

  return (
    <FormControl {...containerProps} isInvalid={errorMessage}>
      <Stack>
        <FormControl.Label>{label}</FormControl.Label>
        <Input
          variant="underlined"
          p="4"
          bg="#F3F5F7"
          borderRadius="xl"
          ref={ref}
          type="text"
          // name={name}
          value={value}
          onChangeText={onChange}
          {...props}
        />
        <FormControl.HelperText>{helperText}</FormControl.HelperText>
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {errorMessage}
        </FormControl.ErrorMessage>
      </Stack>
    </FormControl>
  );
};
