import { ISession } from "@utils/models/Session";
import {
  storeJsonData,
  STORAGE,
  getJsonData,
  getStringData,
  storeStringData,
  removeData,
} from "@utils/services/storage";
import { keycloak } from "@utils/services";
import { useCallback, useEffect, useMemo, useState } from "react";

export interface useSessionReturnOptions {
  isAuthenticated: boolean;
  isLoading: boolean;
  actions: {
    logout: () => void;
    storeCredentials: (credentials: any) => void;
  };
  session: ISession | null;
}

export const useSession = (): useSessionReturnOptions => {
  const [session, setSession] = useState<null | ISession>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const logout = useCallback(() => {
    removeData(STORAGE.LOCAL_SESSION);
    setIsAuthenticated(false);
    setSession(null);
    setIsLoading(false);
  }, [setIsAuthenticated, setSession, setIsLoading]);

  const storeCredentials = useCallback(
    (tokens: any) => {
      if (tokens.token) {
        const session = getJsonData(STORAGE.LOCAL_SESSION);

        // storeJsonData(STORAGE.LOCAL_SESSION, newSession);
        console.log("TOKEEENS", tokens);
        storeJsonData(STORAGE.TOKENS, tokens);

        // setSession(session);
        setIsAuthenticated(true);
        setIsLoading(false);
      }
    },
    [setIsLoading, setIsAuthenticated]
  );

  const startKeycloakFlow = useCallback(async () => {
    const tokens = getJsonData(STORAGE.TOKENS);
    try {
      const authenicated = await keycloak.init({
        // onLoad: "login-required",
        idToken: tokens?.idToken,
        token: tokens?.token,
        refreshToken: tokens?.refreshToken,
        checkLoginIframe: false,
      });
      console.log("AAAAUUUTHHHH ", authenicated, keycloak.token);
      if (authenicated) {
        const tokens = {
          token: keycloak.token,
          refreshToken: keycloak.refreshToken,
          idToken: keycloak.idToken,
        };
        storeCredentials(tokens);
      } else {
        keycloak?.login();
      }
    } catch (error) {
      console.log("url", process.env.REACT_APP_AUTH_URL);
      console.log("realm", process.env.REACT_APP_AUTH_REALM);
      console.log("clientId", process.env.REACT_APP_AUTH_CLIENT_ID);
      console.log("ERROR AUTHENTICATING", error);
      keycloak.login();
    } finally {
      setIsLoading(false);
    }
  }, [storeCredentials, setIsLoading]);

  useEffect(() => {
    startKeycloakFlow();
  }, [startKeycloakFlow]);

  return useMemo(
    () => ({
      session,
      isAuthenticated,
      isLoading,
      actions: { storeCredentials, logout },
    }),
    [session, isAuthenticated, isLoading, storeCredentials, logout]
  );
};
