import { QueryClientProvider } from "@tanstack/react-query";
import { NativeBaseProvider } from "native-base";
import { theme } from "theme";
import { BrowserRouter } from "react-router-dom";
import { RootRouter } from "routes";
import { queryClient } from "@api";
import { createGlobalStyle } from "styled-components";
import { AuthContextProvider, ModalProvider } from "@contexts";
import { registeredModals } from "@components/Modals";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Urbanist', serif;
    margin: 0;
    background-color: white;
    overflow: hidden;
  }
`;

export default function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <NativeBaseProvider theme={theme}>
          <GlobalStyle />
          <AuthContextProvider>
            <ModalProvider modals={registeredModals}>
              <RootRouter />
            </ModalProvider>
          </AuthContextProvider>
        </NativeBaseProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
