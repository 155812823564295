// import { useNavigation } from "@react-navigation/native";
import { IconDownload, IconSettings } from "@tabler/icons-react";
import { Button, Skeleton } from "components";
import {
  Avatar,
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  IconButton,
  Link,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, ReactNode } from "react";

export interface WidgetProps extends InterfaceBoxProps {
  title?: string;
  action?: string;
  children: ReactNode;
  onClick?: () => void;
  isLoading?: boolean;
}

export interface WidgetInstanceProps<T>
  extends Omit<WidgetProps, "action" | "children" | "onClick"> {
  data: T[];
}

export const Widget: FC<WidgetProps> = ({
  title,
  action,
  children,
  isLoading = false,
  onClick,
  ...props
}) => {
  return (
    <Skeleton w="100%" minH="250px" rounded="xl" isLoading={isLoading}>
      <VStack
        // shadow="4"

        bg="white"
        p="4"
        shadow={2}
        rounded="xl"
        minW="200px"
        minH="250px"
        w="100%"
        space="4"
        {...props}
      >
        <HStack justifyContent="space-between" width="100%" alignItems="center">
          <Heading fontSize="md">{title}</Heading>

          {typeof action === "string" ? (
            <Link onPress={onClick}>
              <Text bold color="primary.400">
                {action}
              </Text>
            </Link>
          ) : (
            <Button
              bg="transparent"
              w="50px"
              h="50px"
              borderRadius="full"
              _hover={{ bg: "light.400" }}
              _pressed={{ bg: "light.400" }}
              onClick={onClick}
            >
              <IconDownload size={20} color="black" />
            </Button>
          )}
        </HStack>
        {children}
      </VStack>
    </Skeleton>
  );
};
