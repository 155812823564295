import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en/en.json";
import es from "./es/es.json";
import ru from "./ru/ru.json";
import ukr from "./ukr/ukr.json";

export const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
  ru: {
    translation: ru,
  },
  ukr: {
    translation: ukr,
  },
};
i18next.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: "es",
  debug: true,
  resources,
});
