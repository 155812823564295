import { Navigate, Route, Routes } from "react-router-dom";
import {
  Analytics,
  ChangePasswordForm,
  EditProfileForm,
  Home,
  Logout,
  Music,
  Notifications,
  Settings,
  SignIn,
  SongList,
  UploadSong,
  UploadAlbum,
  Users,
  Videos,
} from "@pages";
import { CommonLayout } from "@components/";
import { Dashboard, Posts } from "pages/private/Analytics/components";
import { Spinner } from "native-base";
import { useAuth } from "@contexts";

export const RootRouter = () => {
  // const { initialized } = useKeycloak();
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading || !isAuthenticated) {
    return <Spinner />;
  }

  return (
    <Routes>
      <Route path="/" element={<CommonLayout />}>
        <Route index element={<Home />} />
        <Route key="Analytics" path="analytics" element={<Analytics />}>
          <Route index element={<Dashboard />} />
          <Route path="posts/:id?" element={<Posts />} />
        </Route>
        <Route path="music" element={<Music />}>
          <Route index element={<SongList />} />
          <Route path="upload" element={<UploadSong />} />
          <Route path="uploadAlbum" element={<UploadAlbum />} />
        </Route>
        <Route path="videos" element={<Videos />} />
        <Route path="followers" element={<Users />} />
        <Route path="admins" element={<Users />} />
        <Route path="cancellation" element={<Users />} />

        <Route path="notifications" element={<Notifications />} />
        <Route path="settings" element={<Settings />}>
          <Route index element={<EditProfileForm />} />
          <Route path="changePassword" element={<ChangePasswordForm />} />
        </Route>
        <Route path="logout" element={<Logout />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
