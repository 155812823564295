// import { useNavigation } from "@react-navigation/native";
import {
  IconDownload,
  IconEditCircle,
  IconSettings,
} from "@tabler/icons-react";
import { useTranslation } from "@utils/hooks";
import { Button, EmptyButton, Skeleton } from "components";
import {
  Avatar,
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  IconButton,
  Link,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, ReactNode } from "react";

export interface ISummarySection {
  title: string;
  onClick: (section: string) => void;
  items: { name: string; value: string }[];
}

export interface SummarySectionProps extends InterfaceBoxProps {
  sections: ISummarySection[];
}

export const SummarySection: FC<SummarySectionProps> = ({
  sections,
  ...props
}) => {
  return (
    <HStack>
      {sections.map(({ title, items, onClick }, index) => (
        <VStack space="2" w={`calc(100% / ${sections.length})`} key={index}>
          <HStack justifyContent="space-between" px="4">
            <Heading bold color="secondary.300">
              {title}
            </Heading>
            <EmptyButton
              rounded="full"
              width="40px"
              height="40px"
              onClick={() => onClick(title)}
            >
              <IconEditCircle color="black" size={20} />
            </EmptyButton>
          </HStack>
          {items.map(({ name, value }, index) => (
            <HStack space="1" px="6" key={index}>
              <Text>{name}:</Text>
              <Text bold>{value}</Text>
            </HStack>
          ))}
        </VStack>
      ))}
    </HStack>
  );
};
