import React, {
  DOMElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, HStack, Input, VStack, Text, Center } from "native-base";
import { Button } from "@components";
import { IconCloudUpload } from "@tabler/icons-react";
import { IFileDef, IFileItem } from "@utilsmodels/FileItem";
import { FileItem } from "./components/FileItem";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import {
  SupportedFileTypes,
  generateBlobFromFile,
  useFileUpload,
} from "@utils/hooks";

export interface FileUploadProps {
  uploadUrl?: string;
  startUpload?: boolean;
  multiple?: boolean;
  supportedType?: SupportedFileTypes;
  inputProps?: HTMLInputElement;
  placeholder?: string | undefined | null;
  value?: IFileDef | IFileDef[];
  onUploadCompleted?: () => void;
  onChange?: (files: IFileDef[] | []) => void;
}

type FileUploadMutationVariables = FormData;
type FileUploadMutationData = unknown; // Replace with the expected response data type

export function FileUpload({
  uploadUrl,
  startUpload = false,
  multiple = true,
  supportedType = SupportedFileTypes.ALL,
  placeholder = "No files selected",
  inputProps,
  value = [],
  onUploadCompleted = () => {},
  onChange = () => {},
}: FileUploadProps) {
  const { selectFile, removeFile, ref, selectedFiles } = useFileUpload({
    defaultFiles: value,
  });

  useEffect(() => {
    onChange(selectedFiles);
  }, [selectedFiles]);

  const inputPropsGenerated = inputProps !== undefined ? inputProps : {};
  return (
    <VStack p="4" bg="light.200" h="100%" maxH={"460px"} overflowY={"scroll"}>
      <input
        type="file"
        accept={supportedType}
        multiple={multiple ? true : false}
        style={{ visibility: "hidden" }}
        onChange={({ target: { files } }) =>
          files?.length && selectFile(Array.from(files))
        }
        ref={ref}
        placeholder="Select files to upload"
        {...inputPropsGenerated}
      />
      {selectedFiles.length ? (
        <HStack space="2">
          {selectedFiles.map((file) => (
            <Box mt="2">
              <FileItem
                file={file}
                disabled={supportedType === SupportedFileTypes.DISABLED}
                onRemove={removeFile}
              />
            </Box>
          ))}
        </HStack>
      ) : (
        <VStack alignItems="center">
          <Text>{placeholder}</Text>
          <Button
            onClick={() => ref.current.click()}
            bg="transparent"
            _hover={{ bg: "transparent" }}
            _pressed={{ bg: "transparent" }}
          >
            <VStack alignItems="center">
              <IconCloudUpload size={36} color="gray" />
              <Text bold color="secondary.600">
                Upload
              </Text>
            </VStack>
          </Button>
        </VStack>
      )}
    </VStack>
  );
}
