import { IPostResponse } from "@utilsmodels/PostResponse";
import { GET_ALBUMS_URL } from "./getAlbumsApi";
import { GET_SONGS_URL } from "./getAllSongsApi";
import { networkClient } from "./networkClient";

export const deleteAlbumApi = async (payload: any) => {
  const { data } = await networkClient.delete(
    `${GET_ALBUMS_URL}/${payload.albumId}`
  );
  return data;
};
