// import { useNavigation } from "@react-navigation/native";
import { IconDownload, IconSettings } from "@tabler/icons-react";
import { Button, Skeleton } from "components";
import {
  Avatar,
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  IconButton,
  Link,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, ReactNode } from "react";

export interface SectionStepperProps extends InterfaceBoxProps {
  steps: string[];
  currentStep: string;
  isLoading?: boolean;
}

export const SectionStepper: FC<SectionStepperProps> = ({
  steps,
  currentStep,
  isLoading = false,
  ...props
}) => {
  return (
    <Skeleton w="100%" minH="250px" rounded="xl" isLoading={isLoading}>
      <HStack bg="white" p="4" shadow="2" rounded="xl" w="100%" {...props}>
        {steps.map((step, index) => {
          const isActive = currentStep === step;

          return (
            <VStack key={index} w={`calc(100% / ${steps.length})`} space="2">
              <HStack space="4" alignItems="center" justifyContent="center">
                <Box
                  rounded="full"
                  size="6"
                  justifyContent="center"
                  alignItems="center"
                  borderWidth="1"
                  borderColor="primary.400"
                  bg={isActive ? "primary.400" : "transparent"}
                >
                  <Text color={isActive ? "white" : "black"}>{index + 1}</Text>
                </Box>
                <Text bold color={isActive ? "primary.400" : "black"}>
                  {step}
                </Text>
              </HStack>
              <Box
                rounded="1"
                h="1"
                bg={isActive ? "primary.400" : "secondary.400"}
              />
            </VStack>
          );
        })}
      </HStack>
    </Skeleton>
  );
};
