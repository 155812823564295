import {
  useDisclose,
  Center,
  Box,
  Stagger,
  IconButton,
  Icon,
  HStack,
  Tooltip,
  Text,
} from "native-base";
import { IconPlus } from "@tabler/icons-react";
import { useModal } from "@contexts";
import { ModalTypes, Button } from "@components";
import { ReactNode } from "react";
import { motion } from "framer-motion";
import { AnimatedBox, AnimatedVStack } from "components/AnimatedBox";
import { position } from "native-base/lib/typescript/theme/styled-system";

export interface IStaggerButtonOption {
  icon: ReactNode;
  onClick: () => void;
}

export interface StaggerButtonProps {
  options: IStaggerButtonOption[];
}

export const StaggerButton = ({ options = [] }: any) => {
  const { isOpen, onToggle } = useDisclose();

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerDirection: -1,
      },
    },
  };
  const button = {
    hidden: { rotate: 0 },
    show: {
      rotate: 45,
    },
  };

  const item = {
    hidden: { opacity: 0, display: "none" },
    show: { opacity: 1, display: "block" },
  };

  return (
    <AnimatedVStack space="4">
      <AnimatedVStack
        position="absolute"
        top="-120"
        left="-50"
        space="4"
        variants={container}
        initial="hidden"
        animate={isOpen ? "show" : "hidden"}
      >
        {options?.map(({ icon, name, onClick }: any) => (
          <AnimatedBox variants={item}>
            {/* <Tooltip label={"top left"} offset={20}> */}

            <HStack space="2" alignItems="center">
              <Box
                p="2"
                bg="black"
                h="30px"
                rounded="xl"
                justifyContent="center"
              >
                <Text color="white">{name}</Text>
              </Box>
              <Button
                borderRadius="full"
                size="lg"
                onClick={onClick}
                bg="primary.400"
              >
                {icon}
              </Button>
            </HStack>

            {/* </Tooltip> */}
          </AnimatedBox>
        ))}
      </AnimatedVStack>
      <HStack alignItems="center">
        <Button
          borderRadius="full"
          size="lg"
          onClick={onToggle}
          bg="primary.400"
        >
          <AnimatedBox animate={isOpen ? "show" : "hidden"} variants={button}>
            <IconPlus size={26} color="white" />
          </AnimatedBox>
        </Button>
      </HStack>
    </AnimatedVStack>
  );
};
