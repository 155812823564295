import { extendTheme } from "native-base";

export const theme = extendTheme({
  fontConfig: {},
  colors: {
    primary: {
      "50": "#10110710e",
      "100": "#f8eadc",
      "200": "#e9ccb0",
      "300": "#d5af89",
      "400": "#c5915e",
      "500": "#b0804f",
      "600": "#8f6e4e",
      "700": "#705d4a",
      "800": "#544c43",
      "900": "#3c3a39",
    },
    secondary: {
      "50": "#103105107",
      "100": "#f4efeb",
      "200": "#e2d9d3",
      "300": "#cec4bc",
      "400": "#bcafa5",
      "500": "#aba29c",
      "600": "#999795",
      "700": "#858b8f",
      "800": "#70808b",
      "900": "#5e7484",
    },
  },
});
