import { FileUpload } from "components/FileUpload";
import { Button, RadioField, SwitchButton } from "@components/";
import { Text, VStack, Box, Stack, Spinner, useToast } from "native-base";
import { useEffect, useState } from "react";
import { useModal } from "@contexts";
import {
  SupportedFileTypes,
  useIsMobile,
  useTranslation,
  withFormFieldProps,
} from "@utils/hooks";
import { useGeolocated } from "react-geolocated";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { FormField } from "components/FormField";
import { IFileDef } from "@utilsmodels/FileItem";
import { ICommonFormProps } from "@utilsmodels/CommonFormProps";
import { IconEdit, IconKey } from "@tabler/icons-react";
import { withFormField } from "@utils/hoc";

import { GET_POTST_URL, queryClient } from "@api";
import { GET_REELS_URL } from "api/getReelsApi";

export interface UploadPostFormProps
  extends ICommonFormProps<{
    files: [];
    description: "";
    title: "";
    premium: boolean;
  }> {
  premiumSwitch?: boolean;
  supportedFileType: SupportedFileTypes;
}

const FieldUploadForm = withFormFieldProps(FileUpload);
const SwitchButtonForm = withFormField(SwitchButton);

export const UploadPostForm: React.FC<UploadPostFormProps> = ({
  supportedFileType,
  defaultValues,
  premiumSwitch = true,
  isLoading,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isDisabled = supportedFileType === SupportedFileTypes.DISABLED;
  // const { hideModal } = useModal();

  // const { show, closeAll } = useToast();
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  //   useGeolocated({
  //     positionOptions: {
  //       enableHighAccuracy: true,
  //     },
  //     userDecisionTimeout: 5000,
  //   });
  const { control, handleSubmit, formState } = useForm<any>({
    defaultValues: defaultValues ?? {
      files: [],
      description: "",
      premium: false,
    },
  });

  const submit = (form: any) => {
    const newForm: any = new FormData();

    newForm.append("title", form.description);
    newForm.append("description", form.description);
    newForm.append("premium", form.premium);
    if (!isDisabled) {
      form.files.map((file: IFileDef) =>
        newForm.append("files", file.file, file.file.name)
      );
    }

    newForm.append("latitude", 0);
    newForm.append("longitude", 0);
    setLoading(true);
    onSubmit(newForm)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
    // submitHandler(form);
  };

  console.log("defaultValues", defaultValues);

  return (
    <Stack direction={isMobile ? "column" : "row"}>
      <Box w={{ lg: "50%" }}>
        <FieldUploadForm
          form={{ control, name: "files" }}
          supportedType={supportedFileType ?? SupportedFileTypes.VIDEO}
          placeholder={t("home.modals.createPost.form.file.label")}
        />
      </Box>
      <VStack w={{ lg: "50%" }} px="6" py="6" space="4">
        <FormField.TextArea
          form={{ control, name: "description" }}
          label={t("home.modals.createPost.form.description.label")}
        />
        <Box w="170px">
          {premiumSwitch ? (
            <SwitchButtonForm
              form={{ control, name: "premium" }}
              label="Premium content"
              options={[
                {
                  label: t("home.modals.createPost.form.premium.options.yes"),
                  value: true,
                  Icon: IconEdit,
                },
                {
                  label: t("home.modals.createPost.form.premium.options.no"),
                  value: false,
                  Icon: IconKey,
                },
              ]}
            />
          ) : (
            <></>
          )}
        </Box>

        <Button onClick={handleSubmit(submit)} isLoading={loading}>
          <Text bold color="white">
            {t("home.modals.createPost.form.submitButton")}
          </Text>
        </Button>
      </VStack>
    </Stack>
  );
};
