import { IPostResponse } from "@utilsmodels/PostResponse";
import { networkClient } from "./networkClient";

export const PUT_REEL_URL = "/reels";

export const putReelApi = async ({
  form,
  reel,
}: {
  form: any;
  reel: IPostResponse;
}) => {
  const { data } = await networkClient.put(`${PUT_REEL_URL}/${reel.id}`, form);
  return data;
};
