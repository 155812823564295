import { useState } from "react";
import { HStack, Text } from "native-base";
import { Button } from "@components/";
import { IHStackProps } from "native-base/lib/typescript/components/primitives/Stack/HStack";

export interface SwitchButtonProps {
  options: {
    Icon: (props: any) => JSX.Element;
    label: string;
    value?: string | boolean | number;
  }[];
  value: string;
  onChange: (option: any) => void;
  [k: string]: any;
}

export function SwitchButton({
  options,
  value,
  onChange,
  ...rest
}: SwitchButtonProps) {
  return (
    <HStack
      rounded="full"
      shadow="2"
      bg="white"
      p="1"
      justifyContent="center"
      maxW="300px"
      {...rest}
    >
      {options.map(({ label, value: optionValue, Icon }, index) => (
        <Button
          py="2"
          px="4"
          bg={optionValue === value ? "primary.400" : "white"}
          rounded="full"
          onClick={() => {
            onChange(optionValue);
          }}
        >
          <HStack alignItems="center" space="2">
            <Icon size={14} color="white" />
            <Text color={optionValue === value ? "white" : "secondary.400"}>
              {label}
            </Text>
          </HStack>
        </Button>
      ))}
    </HStack>
  );
}
