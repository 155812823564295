import { IFormField } from "@utils/models/FormField";
import { ComponentType, FC, useEffect } from "react";
import { useController, UseControllerProps } from "react-hook-form";

type TwithFormFieldProps<C> = C & IFormField;

type componentProps<C> = C & {
  form: UseControllerProps;
};

export function withFormFieldProps<C>(
  Component: ComponentType<TwithFormFieldProps<C>>
) {
  return ({
    form: { name, control, rules, defaultValue },
    ...props
  }: componentProps<Omit<C, "onChange" | "value">>) => {
    const {
      field: { value, onChange },
      fieldState: { error },
    } = useController({
      name,
      control,
      rules,
      defaultValue,
    });

    return (
      <Component
        value={value}
        onChange={onChange}
        errorMessage={error?.message}
        {...(props as C)}
      />
    );
  };
}
