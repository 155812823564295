import { IPostResponse } from "@utilsmodels/PostResponse";
import { networkClient } from "./networkClient";

export const PUT_POST_URL = "/posts";

export const putPostApi = async ({
  form,
  post,
}: {
  form: any;
  post: IPostResponse;
}) => {
  console.log("PAYYYLOAD ", { form, post });
  const { data } = await networkClient.put(`${PUT_POST_URL}/${post.id}`, form);
  return data;
};
