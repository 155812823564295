import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { networkClient } from "./networkClient";

export const GET_REELS_URL = "/reels";

export interface IGetAllReelsResponse
  extends IPaginationResponse<IPostResponse> {}

export const getReelsApi = async () => {
  const { data } = await networkClient.get<IGetAllReelsResponse>(GET_REELS_URL);
  return data;
};
