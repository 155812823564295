import { IModalItem, IModalProps } from "@utils/models";
import { FileUpload } from "components/FileUpload";
import { TextAreaField } from "components/TextAreaField";
import { TextField } from "components/TextField";
import { Button, DatePickerField } from "@components/";
import {
  Modal,
  HStack,
  Text,
  Image,
  TextArea,
  VStack,
  Box,
  Heading,
  CheckIcon,
  Select,
} from "native-base";
import { useState } from "react";
import { useModal } from "@contexts";
import { withFormField } from "@utils/hoc";
import { useForm } from "react-hook-form";

export interface CreateNotificationProps extends IModalProps {}

const TextFieldForm = withFormField(TextField);
const TextAreaFieldForm = withFormField(TextAreaField);
// const DatepickerFieldForm = withFormField(DatePickerField);

export const CreateNotification: React.FC<CreateNotificationProps> = ({
  payload,
}) => {
  const [loading, setLoading] = useState(false);
  const [ok, setOk] = useState(false);
  const { hideModal } = useModal();
  const { control } = useForm();
  return (
    <Modal.Content maxWidth="946px">
      <Modal.CloseButton />
      <Modal.Header>Create new notification</Modal.Header>
      <Modal.Body px="0" py="0">
        {ok ? (
          <VStack
            h="4"
            height={"360px"}
            alignItems="center"
            justifyContent="center"
            space="4"
          >
            <Heading bold color="black">
              Upload success
            </Heading>
            <Box w="20%">
              <Button onClick={() => hideModal()}>
                <Text bold color="white">
                  Close
                </Text>
              </Button>
            </Box>
          </VStack>
        ) : (
          <VStack px="6" py="6" space="4">
            <TextFieldForm form={{ control, name: "title" }} label="Title" />
            <TextAreaFieldForm
              form={{ control, name: "content" }}
              label="Content"
            />
            <Select
              selectedValue={""}
              minWidth="200"
              placeholder="Choose Service"
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size="5" />,
              }}
              mt={1}
              onValueChange={(itemValue) => {}}
            >
              <Select.Item label="UX Research" value="ux" />
              <Select.Item label="Web Development" value="web" />
              <Select.Item label="Cross Platform Development" value="cross" />
              <Select.Item label="UI Designing" value="ui" />
              <Select.Item label="Backend Development" value="backend" />
            </Select>
            {/* <DatepickerFieldForm
              form={{ control, name: "content" }}
              label="Trigger date"
            /> */}

            <Button onClick={() => setLoading(true)} isLoading={loading}>
              <Text bold color="white">
                Upload
              </Text>
            </Button>
          </VStack>
        )}
      </Modal.Body>
    </Modal.Content>
  );
};
