import { networkClient } from "./networkClient";

export const UPLOAD_SONG_URL = "/songs";

export interface IUploadSongPayload {}

export const postSongApi = async (post: IUploadSongPayload) => {
  const { data } = await networkClient.post<IUploadSongPayload>(
    UPLOAD_SONG_URL,
    post,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return data;
};
