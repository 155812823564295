import { VStack } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC } from "react";
import { Outlet } from "react-router-dom";

export interface AnalyticsProps extends InterfaceBoxProps {}

export const Analytics: FC<AnalyticsProps> = ({ ...props }) => {
  return (
    <VStack>
      <Outlet />
    </VStack>
  );
};
