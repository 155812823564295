import { AxiosResponse } from "axios";
import { networkClient } from "./networkClient";

export const UPLOAD_MEDIA_URL = "/songs";

export interface IUploadMediaPayload {
  title: string;
  description: string;
  userID: string;
  latitude: string;
  longitude: string;
  shouldPost: boolean;
  premium: boolean;
  files: File[];
  url?: string;
  id: string;
}

export interface IUploadMediaResponse {
  id: string;
  audios: { id: string }[];
  photos: { id: string }[];
  videos: { id: string }[];
  createdAt: string;
  updatedAt: string;
}

export const putSongApi = async (post: any) => {
  const { data } = await networkClient.put<
    IUploadMediaPayload,
    AxiosResponse<IUploadMediaResponse>
  >(`${UPLOAD_MEDIA_URL}/${post?.get("id")}`, post, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
  return data;
};
