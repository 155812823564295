import { Widget, WidgetInstanceProps, WidgetProps } from "@components";
import { Box, HStack, Progress, Text, VStack } from "native-base";
import { FC } from "react";

export interface AudienceWidgetProps extends WidgetInstanceProps<any> {}

export const AudienceWidget: FC<AudienceWidgetProps> = ({ data, ...props }) => {
  const { followers, posts } = { followers: 2313, posts: 123 };
  return (
    <Widget shadow="false" onClick={() => {}} p="0" {...props}>
      <VStack space="2">
        <VStack>
          {data.map(({}: any) => (
            <>
              <Text>10-20</Text>
              <HStack alignItems="center" space="2">
                <Box w="80%">
                  <Progress value={50} _filledTrack={{ bg: "primary.400" }} />
                </Box>
                <Text>15%</Text>
              </HStack>
            </>
          ))}
        </VStack>
      </VStack>
    </Widget>
  );
};
