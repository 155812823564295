import { useQuery } from "@tanstack/react-query";
import { CheckIcon, HStack, IInputProps, Select, Text } from "native-base";
import { FC, useEffect, useState } from "react";

export interface SelectFieldProps extends IInputProps {
  options: {
    Icon?: (props: any) => JSX.Element;
    label: string;
    value: string;
  }[];
  defaultOption?: string;
}

export const SelectField: FC<SelectFieldProps> = ({
  options: initialOptions = [],
  optionsUrl,
  value,
  // defaultOption = options[0].label,
  onChange = () => {},
  ...props
}) => {
  const [options, setOptions] = useState(initialOptions);
  useQuery([optionsUrl], {
    onSuccess: (data: any) => {
      setOptions(
        data?.albums?.content?.map(({ id, title }: any) => ({
          value: id,
          label: title,
        }))
      );
    },
    refetchOnWindowFocus: false,
    enabled: !!optionsUrl,
  });

  return (
    <Select
      selectedValue={value}
      minWidth="200"
      placeholder="Select option"
      _selectedItem={{
        bg: "teal.600",
        endIcon: <CheckIcon size="5" />,
      }}
      mt={1}
      onValueChange={onChange}
      {...(props as any)}
    >
      {options.map((option) => (
        <Select.Item label={option.label} value={option.value} />
      ))}
    </Select>
  );
};
