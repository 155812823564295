import {
  SectionHeader,
  CommonSection,
  SectionStepper,
  SummarySection,
} from "@components";
import { FormWizardProvider, useFormWizard } from "@contexts/";
import { SupportedFileTypes, useTranslation } from "@utils/hooks";
import { withFormWizard } from "@utils/hoc";
import { WizardFormRender } from "components/WizardFormRender";
import { t } from "i18next";
import { HStack, useToast, VStack } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { postAlbumApi } from "api/postAlbumApi";
import { IFileDef } from "@utils/models/FileItem";
import { formatISO } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import { commonDateFormat } from "@utils/helpers/commonDateFormat";
import { putAlbumApi } from "api/putAlbumApi";

export interface UploadAlbumProps extends InterfaceBoxProps {}

export const UploadAlbum: FC<UploadAlbumProps> = withFormWizard(
  ({ ...props }) => {
    const {
      currentForm,
      forms,
      nextForm,
      prevForm,
      submit,
      changeForm,
      formsValues,
      formState,
    } = useFormWizard();
    const navigate = useNavigate();
    const { show: showToast } = useToast();
    const stepNames = forms.map((form) => form.name);
    const isFirst = currentForm.name === stepNames[0];
    const isLast = currentForm.name === stepNames[stepNames.length - 1];
    const sections = forms.map(({ name, fields }) => ({
      title: name,
      items: fields.map(({ form: { defaultValue }, label }) => ({
        name: label.replace("*", ""),
        value: Array.isArray(defaultValue)
          ? defaultValue.map((file) => file.file.name ?? "").join(",")
          : defaultValue,
      })),
      onClick: (formTitle: string) => changeForm(formTitle),
    }));
    const location = useLocation();
    const { t } = useTranslation({ keyPrefix: "music.forms" });
    const { mutateAsync: uploadAlbumApi, isLoading } =
      useMutation(postAlbumApi);
    const { mutateAsync: updateAlbum, isLoading: isLoadingUpdateSong } =
      useMutation(putAlbumApi);
    const [formAlbum, setAlbumForm] = useState<any>({});
    const defaultValues = location?.state?.data;

    const uploadAlbum = async () => {
      const newForm: any = new FormData();

      console.log("NEWW", formAlbum);

      Object.keys(formAlbum).forEach((key) => {
        if (key === "thumbnail") {
          formAlbum.thumbnail.map((file: IFileDef) =>
            newForm.append("thumbnail", file.file, file.file.name)
          );
          return;
        }
        // if (key === "releasedAt") {
        //   newForm.append("releasedAt", "2023-03-03T00:00:000Z"); //formatISO(new Date(formAlbum[key])));

        //   return;
        // }

        newForm.append(key, formAlbum[key]);
      });

      console.log("NEW FORM", newForm);

      await uploadAlbumApi(newForm);
      showToast({
        duration: null,
        // id: ERROR_TOAST_ID,
        title: `${t("commons.messages.successfully")} ${t(
          "commons.messages.uploaded"
        )}`,
        variant: "left-accent",
        description: "",
      });
      navigate("/music");
    };
    const update = useCallback(async () => {
      const songForm: any = new FormData();
      const songValues: any = formsValues.find(
        ({ form }) => form.name === "Album"
      );

      Object.keys(songValues?.values).forEach((key) => {
        if (
          songValues?.values[key] !== "" &&
          key !== "file" &&
          key !== "thumbnail" &&
          key !== "lyrics"
        ) {
          songForm.append(key, songValues?.values[key]);
        }
      });

      songForm.append("id", defaultValues.id);

      await updateAlbum(songForm);

      showToast({
        duration: null,
        title: t("commons.messages.successfully"),
        variant: "left-accent",
        description: t("commons.messages.uploaded"),
      });
      navigate("/music");
    }, [uploadAlbumApi, formsValues]);

    const successHandler = useCallback(
      (data: any) => {
        if (isLast) {
          if (defaultValues) {
            update();
          } else {
            uploadAlbum();
          }
        } else {
          setAlbumForm(data);
          nextForm();
        }
      },
      [isLast, uploadAlbum, nextForm, formState]
    );

    return (
      <VStack py="8" px="6" space="6" maxW="1500px">
        <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
          <SectionHeader title="Add new song" />
        </HStack>

        <SectionStepper steps={stepNames} currentStep={currentForm.name} />

        <CommonSection
          title={currentForm.name}
          backButton={isFirst ? t("buttons.cancel") : t("buttons.previous")}
          nextButton={isLast ? t("buttons.publish") : t("buttons.next")}
          nextButtonProps={{ isLoading: isLoading }}
          onNextClick={() =>
            submit({
              onSuccess: successHandler,
              onInvalid: (data) => {
                console.log("INVALID", data);
              },
            })
          }
          onBackClick={() => (isFirst ? navigate("/music") : prevForm())}
        >
          {isLast ? (
            <SummarySection
              sections={sections.filter(
                ({ title }) => title !== t("summary.title")
              )}
            />
          ) : (
            <WizardFormRender name={currentForm.name} />
          )}
        </CommonSection>
      </VStack>
    );
  },
  () => {
    const { t } = useTranslation({ keyPrefix: "music.forms" });
    const location = useLocation();
    const defaultValues = location?.state?.data;
    console.log("DATAAA", defaultValues);

    return [
      {
        name: "Album",
        order: 1,
        fields: [
          {
            type: "text",
            label: `${t("album.fields.title.label")}*`,
            form: {
              name: "title",
              defaultValue: defaultValues?.title ?? "",
              rules: { required: "Required field" },
            },
          },
          {
            type: "text",
            label: `${t("album.fields.language.label")}*`,
            form: {
              name: "language",
              defaultValue: defaultValues?.language ?? "Ukrain",
              rules: { required: "Required field" },
            },
          },
          {
            type: "text",
            label: `${t("album.fields.artist.label")}*`,
            form: {
              name: "artist",
              defaultValue: defaultValues?.artist ?? "Anilorak",
              rules: { required: "Required field" },
            },
          },
          {
            type: "text",
            label: `${t("album.fields.additionalArtist.label")}`,
            helperText: "Separa con “,” cada artista adicional",
            form: {
              name: "additionalArtists",
              defaultValue: "",
            },
          },

          {
            type: "date",
            label: `${t("album.fields.date.label")}*`,
            form: {
              name: "releasedAt",
              defaultValue: commonDateFormat(
                new Date(defaultValues?.createdAt ?? Date.now())
              ),
              rules: { required: "Required field" },
            },
          },
          {
            type: "text",
            label: "Copyrights",
            form: { name: "copyright", defaultValue: "" },
          },
          {
            type: "options",
            label: `${t("songs.fields.genre.label")}*`,
            options: [
              { value: "Rock", label: "Rock" },
              { value: "Pop", label: "Pop" },
            ],
            form: {
              name: "genre",
              defaultValue: defaultValues?.genre ?? "",
              rules: { required: "Required field" },
            },
          },
          {
            type: "file",
            label: `${t("album.fields.cover.label")}*`,
            supportedFile: SupportedFileTypes.IMAGE,
            helperText: "Recommended size: 1400 x 1400 pixels",
            isDisabled: defaultValues?.thumbnail ? true : false,
            form: {
              name: "thumbnail",
              ...(defaultValues
                ? {}
                : { rules: { required: "Required field" } }),
            },
          },
        ],
      },
      {
        name: t("summary.title"),
        order: 2,
        fields: [],
      },
    ];
  }
);
