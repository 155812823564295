import { CommonSection, TextField, TextAreaField, MStack } from "@components/";
import { useTranslation, withFormFieldProps } from "@utils/hooks";
import { VStack } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export interface ChangePasswordFormProps extends InterfaceBoxProps {}

const TextFieldForm = withFormFieldProps(TextField);
const TextAreaFieldForm = withFormFieldProps(TextAreaField);

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  ...props
}) => {
  const { control } = useForm();
  const navigate = useNavigate();
  const { t } = useTranslation({ keyPrefix: "settings" });

  return (
    <CommonSection
      title={t("forms.changePassword.title")}
      nextButton={t("forms.changePassword.submitButton")}
    >
      <VStack>
        <MStack>
          <TextFieldForm
            form={{ control, name: "Current password" }}
            containerProps={{ w: "300px" }}
            label="Current password"
          />
          <TextFieldForm
            form={{ control, name: "New password" }}
            containerProps={{ w: "300px" }}
            label="New password"
          />
          <TextFieldForm
            form={{ control, name: "Repeat new password" }}
            containerProps={{ w: "300px" }}
            label="Repeat new password"
          />
        </MStack>
      </VStack>
    </CommonSection>
  );
};
