import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { networkClient } from "./networkClient";

export const GET_ALL_USERS = "/keycloak";

export interface UserResponse {}

export const getUsersApi = async ({ queryKey }: any) => {
  const { data } = await networkClient.get<IPaginationResponse<UserResponse>>(
    queryKey[0]
  );
  return data;
};
