import {
  CommonSection,
  EmptyButton,
  RadioField,
  SectionHeader,
  Table,
  TextField,
  TextAreaField,
} from "@components/";
import { IconTrash, IconPlus, IconEdit, IconPhone } from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation, withFormFieldProps } from "@utils/hooks";
import {
  VStack,
  HStack,
  Button,
  Image,
  Text,
  Switch,
  Radio,
  Box,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useUsersTable } from "./hooks/useUsersTable";

export interface UsersProps extends InterfaceBoxProps {}

export const Users: FC<UsersProps> = ({ ...props }) => {
  const { control } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName: any = useMemo(
    () => location.pathname.split("/")[1],
    [location]
  );
  const { t } = useTranslation({ keyPrefix: "commons" });
  const { columns, fetcher, url } = useUsersTable(pathName);

  const titles: any = {
    cancellation: t("messages.cancellation") + " " + t("messages.list"),
    followers: t("messages.followers") + " " + t("messages.list"),
    admins: t("messages.admins") + " " + t("messages.list"),
  };

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title={titles[pathName]} />
      </HStack>
      <Outlet />
      <Table columns={columns} fetcher={fetcher} url={url} />
    </VStack>
  );
};
