import { useMutation } from "@tanstack/react-query";
import {
  deletePostApi,
  deleteReelApi,
  GET_POTST_URL,
  putPostApi,
  putReelApi,
  queryClient,
  uploadPostApi,
  uploadReelApi,
} from "@api/";
import { useEffect, useMemo } from "react";
import { ModalTypes } from "@components/";
import { SupportedFileTypes, useTranslation } from "@utils/hooks";
import { useModal } from "@contexts/";
import { UploadPostForm, UploadPostFormProps } from "../forms";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { Modal, useToast } from "native-base";
import { createFileDefInstances } from "@utils/helpers/createFileDefIntances";
import { getFileFromUrl } from "@utils/helpers/getFileFromUrl";
import { addElipsis } from "@utils/helpers/addElipsis";
import { IFileDef } from "@utilsmodels/FileItem";
import { GET_REELS_URL } from "api/getReelsApi";
import { capitalizeFirstLetter } from "@utils/helpers/capitalizeFirstLetter";

export type EntryType = "post" | "reel";

export type Entry = IPostResponse;

const SUCCESS_TOAST_ID = "success";

export const useEntryActions = (type: EntryType) => {
  const { showModal, hideModal } = useModal();
  const { t } = useTranslation();
  const isPost = type === "post";
  const toast = useToast();

  const successHandler = async () => {
    try {
      queryClient.invalidateQueries();
      if (!toast.isActive(SUCCESS_TOAST_ID)) {
        reset();
        hideModal();
        toast.show({
          duration: 3,
          id: SUCCESS_TOAST_ID,
          title: "Uploaded!", //t("content.networkError.title"),
          variant: "left-accent",
          description: "refresh feed", //t("content.networkError.description"),
          background: "primary.400",
        });
      }
    } catch (error) {
      console.log("ERRROR", error);
      // toast.show
    }
  };

  const {
    mutateAsync: editEntry,
    isLoading: isLoadingEdit,
    isSuccess: isEditSuccess,
  } = useMutation(
    (args: any) => (isPost ? putPostApi(args) : putReelApi(args)),
    {
      onSuccess: successHandler,
    }
  );
  const { mutateAsync: removeEntry, isLoading: isLoadingRemove } = useMutation(
    (args: any) => (isPost ? deletePostApi(args) : deleteReelApi(args)),
    {
      onSuccess: successHandler,
    }
  );
  const {
    mutateAsync: uploadPost,
    isLoading: isUploadLoading,
    isSuccess: isUploadSuccess,
    reset,
  } = useMutation(
    (args: any) => (isPost ? uploadPostApi(args) : uploadReelApi(args)),
    {
      onSuccess: successHandler,
    }
  );
  const isLoading = useMemo(
    () => isLoadingRemove || isLoadingEdit || isUploadLoading,
    [isLoadingRemove, isLoadingEdit, isUploadLoading]
  );

  function remove<T>(data: T) {
    showModal(ModalTypes.CONFIRM, {
      title: t("commons.messages.deleteTitle"),
      description: t("commons.messages.deleteDescription"),
      onConfirm: () => {
        return removeEntry(data);
      },
      onCancel: () => {
        hideModal();
      },
    });
  }

  async function edit(data: IPostResponse) {
    const filesFromUrl: any = data.files.map((file) => ({
      file: file.thumbnailPath, //addElipsis(file.mediaType, 20),
    }));
    console.log("onEdit", data);
    showModal(ModalTypes.COMMON, {
      title: "post",
      body: (
        <UploadPostForm
          isLoading={isLoading}
          supportedFileType={SupportedFileTypes.DISABLED}
          onSubmit={(formData: any) => {
            const form = Object.fromEntries(formData.entries());
            const payload: any = {
              form,
            };
            if (isPost) {
              payload.post = data;
            } else {
              payload.reel = data;
            }
            return editEntry(payload);
          }}
          defaultValues={{
            description: data?.description ?? "",
            files: filesFromUrl,
            premium: data.premium,
          }}
        />
      ),
    });
  }

  function upload() {
    showModal(ModalTypes.COMMON, {
      title: `Manage ${capitalizeFirstLetter(type)}`,
      body: (
        <UploadPostForm
          premiumSwitch={isPost}
          isLoading={isLoading}
          supportedFileType={
            isPost ? SupportedFileTypes.IMAGE_VIDEO : SupportedFileTypes.VIDEO
          }
          onSubmit={uploadPost}
        />
      ),
    });
  }

  return { remove, edit, upload, isLoading };
};
