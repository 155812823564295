import {
  Text,
  Image,
  Box,
  HStack,
  Avatar,
  IconButton,
  Skeleton,
  VStack,
  Divider,
  Icon,
  Heading,
  IButtonProps,
} from "native-base";
import { FC, ReactNode, RefAttributes } from "react";
import { Button } from "@components/";
import { IVStackProps } from "native-base/lib/typescript/components/primitives/Stack/VStack";

export interface CommonSectionProps {
  title: string;
  nextButton?: string;
  backButton?: string;
  onNextClick?: any;
  onBackClick?: () => void;
  containerProps?: IVStackProps;
  nextButtonProps?: IButtonProps;
  backButtonProps?: IButtonProps;
  children: ReactNode | ReactNode[];
}

export const CommonSection: FC<CommonSectionProps> = ({
  title,
  nextButton,
  backButton,
  nextButtonProps,
  backButtonProps,
  containerProps,
  onNextClick,
  onBackClick,
  children,
}) => {
  return (
    <VStack minW="400px" rounded="xl" bg="white" space="4" shadow="2">
      <Box px="6" pt="4">
        <Heading fontSize="xl">{title} </Heading>
      </Box>
      <Divider />
      <VStack px="6" py="4" minH="500px" {...containerProps}>
        {children}
      </VStack>

      {(backButton || nextButton) && (
        <VStack>
          <Divider />
          <HStack px="6" py="4" justifyContent="space-between">
            {backButton ? (
              <Button
                rounded="xl"
                py="3"
                px="8"
                variant="outline"
                onClick={onBackClick}
                {...backButtonProps}
              >
                {backButton}
              </Button>
            ) : (
              <Box />
            )}

            {nextButton && (
              <Button
                py="3"
                px="8"
                bg="primary.400"
                rounded="xl"
                onClick={onNextClick}
                {...nextButtonProps}
              >
                {nextButton}
              </Button>
            )}
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
