import React, { FC } from "react";
import { Box, IInputProps, theme } from "native-base";
import { TextField } from "./TextField";
import { IconSearch } from "@tabler/icons-react";
export interface SearchFieldProps extends IInputProps {}

export const SearchField: FC<SearchFieldProps> = ({ ...props }) => {
  return (
    <TextField
      type="text"
      bg="gray.100"
      borderRadius="4px"
      py="4"
      borderBottomColor={"transparent"}
      InputLeftElement={
        <Box pl="4">
          <IconSearch size={20} color={theme.colors.gray[400]} />
        </Box>
      }
      autoCapitalize="none"
      placeholder="Search..."
      fontSize="md"
      {...props}
    />
  );
};
