import { useMutation } from "@tanstack/react-query";
import { IFileDef, IFileItem } from "@utils/models/FileItem";
import { createFileDefInstances } from "@utils/helpers/createFileDefIntances";
import axios from "axios";
import { useState, useRef, useCallback, useEffect } from "react";

export enum SupportedFileTypes {
  DISABLED = "DISABLED",
  VIDEO = "video/*",
  IMAGE = "image/*",
  MUSIC = "audio/*",
  IMAGE_VIDEO = "image/*, video/*",
  ALL = "*",
}

type FileUploadMutationVariables = FormData;
type FileUploadMutationData = unknown; // Replace with the expected response data type

export interface useFileUploadProps {}

export function generateBlobFromFile(file: File) {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Promise<Blob>((resolve) => {
    reader.onload = () => {
      const arrayBuffer: any = reader.result;
      const blob = new Blob([arrayBuffer], { type: file.type });
      resolve(blob);
    };
  });
}

export const useFileUpload = (options: any) => {
  const [selectedFiles, setSelectedFiles] = useState<IFileDef[] | []>(
    options?.defaultFiles ??
      [
        // { name: "Photo 1.png", size: "10mb", progress: 40 },
      ]
  );
  const ref = useRef<any>(null);

  const selectFile = async (files: File[]) => {
    // const fileWithBlob = files.map(async (file: File) => {
    //   const blob = await generateBlobFromFile(file);
    //   const newFile: IFileDef = {
    //     file,
    //     blob,
    //   };
    //   return newFile;
    // });
    // const newFiles = await Promise.all(fileWithBlob);
    const newFiles = await createFileDefInstances(files);
    setSelectedFiles(newFiles);
  };

  const removeFile = (file: File) => {
    setSelectedFiles(
      selectedFiles.filter((selected) => selected.file.name !== file.name)
    );
  };

  return { selectedFiles, selectFile, removeFile, ref };
};
