import {
  Box as NBox,
  Center,
  IconButton,
  Pressable,
  Text,
  VStack,
  HStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, ReactNode, useState } from "react";
import { Outlet, useNavigation, useOutletContext } from "react-router-dom";
import {
  IconLogout,
  IconHome,
  IconBrandGoogleAnalytics,
  IconPlaylist,
  IconSettings,
  IconVideo,
  IconNotification,
} from "@tabler/icons-react";
import { Menu } from "@components/Menu";
import { motion, MotionProps } from "framer-motion";

export interface AnimatedBoxProps extends InterfaceBoxProps {}

export const AnimatedBox = motion(NBox);

export const AnimatedVStack = motion(VStack);
export const AnimatedHStack = motion(HStack);
// export const AnimatedBox: FC<AnimatedBoxProps> = ({ children, ...props }) => {
//   return <Box {...props} />;
// };
