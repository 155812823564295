import { FileUpload } from "components/FileUpload";
import { Button, RadioField, SwitchButton } from "@components/";
import { Text, VStack, Box, Stack, Spinner, useToast } from "native-base";
import { useEffect, useMemo, useState } from "react";
import { useAuth, useModal } from "@contexts";
import {
  SupportedFileTypes,
  useIsMobile,
  useTranslation,
  withFormFieldProps,
} from "@utils/hooks";
import { useGeolocated } from "react-geolocated";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { FormField } from "components/FormField";
import { IFileDef } from "@utilsmodels/FileItem";
import { ICommonFormProps } from "@utilsmodels/CommonFormProps";
import { IconEdit, IconKey } from "@tabler/icons-react";
import { withFormField } from "@utils/hoc";
import YouTube from "react-youtube";

export interface AddYoutubeVideoFormProps
  extends ICommonFormProps<{
    title: string;
    url: string;
    [x: string]: any;
  }> {}

const FieldUploadForm = withFormFieldProps(FileUpload);
const SwitchButtonForm = withFormField(SwitchButton);

function getYouTubeVideoId(url: string) {
  // Regular expression pattern to match YouTube video ID in the URL
  const regex = /(?:\?v=|&v=|youtu\.be\/)(.*?)(?:&|$|\?|#)/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    // Handle invalid URL or no match found
    return null;
  }
}

export const AddYoutubeVideoForm: React.FC<AddYoutubeVideoFormProps> = ({
  defaultValues,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation({ keyPrefix: "commons.messages" });
  const isMobile = useIsMobile();

  const { session } = useAuth();

  // const { hideModal } = useModal();
  // const { show, closeAll } = useToast();
  const { control, handleSubmit, formState, watch } = useForm<any>({
    defaultValues: defaultValues ?? {
      title: "",
      url: "",
      premium: false,
    },
  });

  const submit = async (form: any) => {
    const newForm: any = new FormData();

    newForm.append("userID", `${session?.user.id}`);
    newForm.append("shouldPost", "false");
    newForm.append("description", form.title);
    newForm.append("title", form.title);
    newForm.append("url", form.url);

    setLoading(true);
    onSubmit(newForm)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const urlFieldValue = watch("url");
  const youtubeVideoId = useMemo(
    () => getYouTubeVideoId(urlFieldValue),
    [urlFieldValue]
  );

  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      px="6"
      py="4"
      space="2"
      minH={"420px"}
    >
      <Box w={{ lg: "50%" }} h="100%" justifyContent="space-between">
        {youtubeVideoId ? (
          <YouTube
            videoId={youtubeVideoId} // defaults -> ''
            opts={{
              height: "390",
              width: "100%",
              playerVars: {
                // https://developers.google.com/youtube/player_parameters
                //   autoplay: 1,
              },
            }}
          />
        ) : (
          <VStack space="4" alignItems="center">
            <Text>Ingrese una url de youtube valida para visualizar</Text>
            <Spinner />
          </VStack>
        )}
      </Box>
      <VStack w={{ lg: "50%" }} space="4">
        <VStack>
          <FormField.Text
            form={{ control, name: "title" }}
            label={t("title")}
          />
          <FormField.Text form={{ control, name: "url" }} label={t("url")} />
        </VStack>

        {/* <Box w="170px">
          <SwitchButtonForm
            form={{ control, name: "premium" }}
            label={t("premium")}
            options={[
              {
                label: t("yes"),
                value: true,
                Icon: IconEdit,
              },
              {
                label: t("no"),
                value: false,
                Icon: IconKey,
              },
            ]}
          />
        </Box> */}

        <Button onClick={handleSubmit(submit)} isLoading={loading}>
          <Text bold color="white">
            {t("upload")}
          </Text>
        </Button>
      </VStack>
    </Stack>
  );
};
