import { NavLink } from "react-router-dom";
import { HStack, Link, Text } from "native-base";
import { IconHome, IconHome2, IconHomeBolt } from "@tabler/icons-react";

export const BreadcrumbTrail = ({ breadcrumbs }: any) => {
  return (
    <HStack space="2">
      <IconHome size={20} color="black" />
      <HStack space="1">
        {breadcrumbs.map(
          ({ match, breadcrumb, location }: any, index: number) => (
            <Link key={match.pathname}>
              <NavLink to={match.pathname} style={{ textDecoration: "none" }}>
                <Text
                  color={
                    match.pathname === location.pathname
                      ? "primary.400"
                      : "text.500"
                  }
                >
                  {breadcrumb}
                  {index !== breadcrumbs.length - 1 && "/"}
                </Text>
              </NavLink>
            </Link>
          )
        )}
      </HStack>
    </HStack>
  );
};
