// import { useNavigation } from "@react-navigation/native";
import { Widget, WidgetInstanceProps, WidgetProps } from "@components/Widget";
import { IconDownload, IconSettings } from "@tabler/icons-react";
import { useTranslation } from "@utils/hooks";
import { IPost } from "@utilsmodels/Post";
import {
  Avatar,
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  IconButton,
  Image,
} from "native-base";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export interface PostsWidgetProps extends WidgetInstanceProps<Partial<IPost>> {}

export const PostsWidget: FC<PostsWidgetProps> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation({ keyPrefix: "analytics" });

  return (
    <Widget
      title={t("widgets.socialmedia.title")}
      action={t("widgets.socialmedia.button")}
      shadow="false"
      onClick={() => navigate("posts")}
      p="0"
    >
      <HStack space="2" flexWrap="wrap">
        {data.map(({ thumbnail }: any) => (
          <Image
            width="80px"
            height="80px"
            rounded="lg"
            mt="2"
            source={{ uri: thumbnail }}
          />
        ))}
      </HStack>
    </Widget>
  );
};
