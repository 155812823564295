import { useTranslation as usei18, UseTranslationOptions } from "react-i18next";
import { useMemo } from "react";

export const useTranslation = (
  options?: UseTranslationOptions<any>,
  namespace = "translation"
) => {
  const i18n = usei18(namespace, options);

  const t = (key: string) => String(i18n.t(key));

  return useMemo(
    () => ({
      ...i18n,
      t,
    }),
    [i18n]
  );
};
