// import { useNavigation } from "@react-navigation/native";
import {
  EmptyButton,
  SectionHeader,
  Table,
  Button,
  CommonSection,
  ModalTypes,
} from "@components/";
import { useModal } from "@contexts/";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/table-core";
import { useTranslation } from "@utils/hooks";
import {
  Box,
  Center,
  HStack,
  IconButton,
  Pressable,
  Text,
  VStack,
  Image,
  Checkbox,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { NotificationSettingsForm } from "./forms";

export interface NotificationsProps extends InterfaceBoxProps {}

const notification = () => {
  return {
    title: "New album release",
    content: "see the new content",
    redirectTo: "/songs/album/123",
    creationDate: "12/12/1212",
    triggerDate: "12/12/1212",
  };
};
export async function fetchNotifications(options: {
  pageIndex: number;
  pageSize: number;
}) {
  // Simulate some network latency
  await new Promise((r) => setTimeout(r, 500));

  return {
    rows: new Array(4).fill(notification()),
    pageCount: 0,
  };
}

export const Notifications: FC<NotificationsProps> = ({ ...props }) => {
  const { t } = useTranslation({ keyPrefix: "notifications" });
  const columns: ColumnDef<any>[] = [
    {
      accessorKey: "title",
      header: t("table.columns.title"),
      footer: (props) => props.column.id,
      cell: (item) => {
        return (
          <HStack space="2" alignItems="center">
            <Checkbox value={""} />
            <Text>{item.getValue ? <>{item.getValue()}</> : ""}</Text>
          </HStack>
        );
      },
    },
    {
      accessorKey: "content",
      header: t("table.columns.content"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "redirectTo",
      header: t("table.columns.redirect"),
      footer: (props) => props.column.id,
    },
    // {
    //   accessorKey: "title",
    //   header: "NOTIFY WHEN",
    //   footer: (props) => props.column.id,
    // },
    // { name: "New album launch", date: "24/05/2023", content: "See the content of the new album", actions: [{ name: 'see more', link: ''}] }
    {
      accessorKey: "triggerDate",
      header: t("table.columns.triggerDate"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "creationDate",
      header: t("table.columns.creationDate"),
      footer: (props) => props.column.id,
    },
  ];
  const { showModal } = useModal();
  const navigate = useNavigate();

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title="Notifications" />
        <Button
          py="3"
          px="6"
          bg="primary.400"
          rounded="xl"
          onClick={() => showModal(ModalTypes.CREATE_NOTIFICATION)}
        >
          <HStack alignItems="center" space="2">
            <IconPlus size={16} color="white" />
            <Text color="white">{t("buttons.addNotification")}</Text>
          </HStack>
        </Button>
      </HStack>

      <NotificationSettingsForm />

      {/* <Table fetcher={fetchNotifications} columns={columns} /> */}
    </VStack>
  );
};
