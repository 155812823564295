import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { networkClient } from "./networkClient";

export const GET_ALBUMS_URL = "/albums";

export interface IGetAlbumsReponse {
  albums: IPaginationResponse<{
    id: string;
    title: string;
    artist: string;
    additionalArtists: string | null;
    genre: string;
    language: string;
    copyright: string | null;
    thumbnail: string;
    size: 0;
    duration: number | null;
    year: number | null;
    releasedAt: string | null;
    createdAt: string;
    updatedAt: string;
  }>;
  albumsSongs: any[];
}
export const getAlbumsApi = async ({ queryKey }: any) => {
  const { data } = await networkClient.get<IGetAlbumsReponse>(GET_ALBUMS_URL);
  return data;
};
