import { AxiosResponse } from "axios";
import { networkClient } from "./networkClient";

export const ALBUMS_URL = "/albums";

export const putAlbumApi = async (post: any) => {
  const { data } = await networkClient.put<any>(
    `${ALBUMS_URL}/${post?.get("id")}`,
    post,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return data;
};
