import { IModalItem } from "@utils/models";
import { Common } from "./Common";
import { Confirm } from "./Confirm";
import { CreateNotification } from "./CreateNotification";

export enum ModalTypes {
  "CREATE_NOTIFICATION" = "CREATE_NOTIFICATION",
  "CONFIRM" = "CONFIRM",
  "COMMON" = "COMMON",
}

export const registeredModals: IModalItem[] = [
  { name: ModalTypes.COMMON, component: Common },
  { name: ModalTypes.CREATE_NOTIFICATION, component: CreateNotification },
  { name: ModalTypes.CONFIRM, component: Confirm },
];

export * from "./Common";
