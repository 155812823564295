import { IModalProps } from "@utils/models";
import { Modal } from "native-base";
import { useTranslation } from "react-i18next";

export interface CommonModalProps extends IModalProps {}

export const Common: React.FC<CommonModalProps> = ({ payload }) => {
  const { t } = useTranslation();
  return (
    <Modal.Content maxWidth="946px">
      <Modal.CloseButton />
      <Modal.Header>{`${payload.title}`}</Modal.Header>
      <Modal.Body px="0" py="0">
        {payload.body}
      </Modal.Body>
    </Modal.Content>
  );
};
