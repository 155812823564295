import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNetworkInterceptor } from "./hooks/useNetworkInterceptor";
import { useSession, useSessionReturnOptions } from "./hooks/useSession";
import { STORAGE } from "@utils/services";
import { queryClient } from "@api*";

const AuthContext = createContext<useSessionReturnOptions>({
  isAuthenticated: false,
  isLoading: true,
  session: null,
  actions: {
    logout: () => {},
    storeCredentials: (credentials: any) => {},
  },
});

export interface AuthContextProps {
  children: React.ReactElement | React.ReactElement[];
}
export const AuthContextProvider: FC<AuthContextProps> = ({ children }) => {
  const session = useSession();
  const value = useMemo(() => session, [session]);
  useNetworkInterceptor();

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
