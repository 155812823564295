import { IconCirclePlus } from "@tabler/icons-react";
import { SupportedFileTypes, useFileUpload } from "@utils/hooks";
import { AnimatedBox } from "components/AnimatedBox";
import { Button } from "components/Button";
import { motion } from "framer-motion";
import {
  FormControl,
  Stack,
  Input,
  WarningOutlineIcon,
  Text,
  useTheme,
  HStack,
  VStack,
} from "native-base";
import { useEffect } from "react";

export const UploadButton = ({
  label,
  helperText,
  errorMessage,
  containerProps,
  supportedFile = SupportedFileTypes.ALL,
  value,
  onChange,
  ...props
}: any) => {
  const { selectFile, removeFile, ref, selectedFiles } = useFileUpload({
    defaultFiles: value,
  });
  const currentFile = selectedFiles.length ? selectedFiles[0] : null;
  const theme = useTheme();

  useEffect(() => {
    onChange(selectedFiles);
  }, [selectedFiles]);

  return (
    <FormControl {...containerProps} isInvalid={errorMessage}>
      <VStack w="170px" zIndex={-200}>
        <FormControl.Label>{label}</FormControl.Label>

        <input
          type="file"
          accept={supportedFile}
          multiple={false}
          style={{ visibility: "hidden", height: "0px" }}
          onChange={({ target: { files } }) =>
            files?.length && selectFile(Array.from(files))
          }
          ref={ref}
          placeholder="Select files to upload"
        />
        <Button
          variant="outline"
          borderColor="primary.300"
          w="170px"
          onClick={() => !props?.isDisabled && ref.current.click()}
          {...props}
        >
          <HStack space="2" alignItems="center">
            <IconCirclePlus size={13} color={theme.colors.primary[500]} />
            <Text bold color="primary.300">
              {currentFile ? currentFile.file.name : "Click to upload"}
            </Text>
          </HStack>
        </Button>
        <FormControl.HelperText>{helperText}</FormControl.HelperText>
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {errorMessage}
        </FormControl.ErrorMessage>
      </VStack>
    </FormControl>
  );
};

// {currentFile ? (
//   <HStack justifyContent="center" alignItems="center" w="100%" h="47px">
//     <Text bold color="primary.300">
//       {currentFile.file.name}
//     </Text>
//   </HStack>
// ) : (
//   <>
