import {
  Text,
  Image,
  Box,
  HStack,
  Avatar,
  IconButton,
  Skeleton,
  VStack,
  Divider,
  Icon,
} from "native-base";
import { FC, ReactNode, RefAttributes } from "react";

export interface CardProps {
  image: string;
  content: any;
  loading?: boolean;
  onClick?: () => void;
}

export const Card: FC<CardProps> = ({ image, content, loading, onClick }) => {
  return (
    <Box bg="white" w="264px" h="316px" borderRadius={8} my={3} shadow="2">
      {loading ? (
        <>
          <Skeleton w="100%" h={462} />
          <Box alignItems="stretch" px={3} py={3}>
            <Skeleton.Text w="50%" />
          </Box>
        </>
      ) : (
        <VStack>
          <Image
            source={{ uri: image }}
            alt="Post Image"
            resizeMode="cover"
            height="244px"
            width="100%"
            roundedTop="xl"
          />
          <Divider />
          <HStack
            alignItems="center"
            py="2"
            justifyContent="center"
            space="4"
            h="72px"
          >
            {content}
          </HStack>
        </VStack>
      )}
    </Box>
  );
};
