// import { useNavigation } from "@react-navigation/native";

import { getVideosApi, GET_VIDEOS_URL, queryClient } from "@api";
import {
  EmptyButton,
  SectionHeader,
  Table,
  Button,
  ModalTypes,
} from "@components/";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { ColumnDef } from "@tanstack/table-core";
import { SupportedFileTypes, useTranslation } from "@utils/hooks";
import { mapToTableData } from "@utils/helpers/mapToTableData";
import { Person } from "components/Table/fetchData";
import { useModal } from "contexts/Modal";
import { HStack, Text, VStack, Checkbox, Image, useToast } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useCallback, useState } from "react";
import { AddYoutubeVideoForm } from "./components/AddYoutubeVideoForm";
import { defaultThumbnailUrl } from "@utils/mock/defaultThumbnailUrl";
import { useMutation } from "@tanstack/react-query";
import { postMediaApi } from "api/postMediaApi";
import { useNavigate } from "react-router-dom";
import { deleteMediaApi } from "api/deleteMediaApi";
import { GET_SONGS_URL } from "api/getAllSongsApi";

export interface VideosProps extends InterfaceBoxProps {}

const notification = () => {
  return {
    title: "New album release",
    url: "https://www.youtube.com/watch?v=WfuyKMaQQbk",
    premium: false,
    // "https://www.youtube.com/watch?v=WfuyKMaQQbk"
    // "https://www.youtube.com/watch?v=uF373Iynpsc"
  };
};
async function fetchNotifications(options: {
  pageIndex: number;
  pageSize: number;
}) {
  // Simulate some network latency
  await new Promise((r) => setTimeout(r, 500));

  return {
    rows: new Array(4).fill(notification()),
    pageCount: 0,
  };
}

export const Videos: FC<VideosProps> = ({ ...props }) => {
  const { t } = useTranslation({ keyPrefix: "commons" });
  const { t: tVideos } = useTranslation({ keyPrefix: "videos" });
  const { showModal, hideModal } = useModal();
  const [rowSelected, setRowSelected] = useState<null | string>(null);
  const [itemSelected, setItemSelected] = useState<null | any>(null);
  const { mutateAsync: uploadVideoApi } = useMutation(postMediaApi);
  const navigate = useNavigate();
  const { show: showToast } = useToast();
  const { t: tCommon } = useTranslation({ keyPrefix: "commons" });
  const { mutateAsync: deleteMedia } = useMutation(deleteMediaApi);

  const removeVideo = useCallback((video: any) => {
    const runDelete = async () => {
      await deleteMedia({ mediaId: video.id });
      await queryClient.invalidateQueries([GET_VIDEOS_URL]);
      hideModal();
    };

    showModal(ModalTypes.CONFIRM, {
      title: tCommon("messages.deleteTitle"),
      description: tCommon("messages.deleteDescription"),
      onConfirm: runDelete,
      onCancel: () => {
        hideModal();
      },
    });
  }, []);

  const columns: ColumnDef<Person>[] = [
    {
      accessorKey: "thumbnail",
      header: t("messages.image"),
      cell: (item) => {
        return (
          <Image
            source={{ uri: item.getValue() ?? defaultThumbnailUrl }}
            alt="Post Image"
            resizeMode="cover"
            size="60px"
            rounded="xl"
            fallbackSource={defaultThumbnailUrl}
          />
        );
      },
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "title",
      header: t("messages.title"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "path",
      header: t("messages.url"),
      footer: (props) => props.column.id,
    },
    {
      accessorKey: "id",
      header: "",
      cell: (item) => (
        <EmptyButton onClick={() => removeVideo(item.row.original)}>
          <IconTrash size={30} color="black" />
        </EmptyButton>
      ),
    },
  ];

  const uploadVideo = async (form: any) => {
    await uploadVideoApi(form);
    showToast({
      duration: null,
      // id: ERROR_TOAST_ID,
      title: t("commons.networkError.title"),
      variant: "left-accent",
      description: t("commons.networkError.description"),
    });
    queryClient.invalidateQueries([GET_VIDEOS_URL]);
    hideModal();
    // navigate("/videos");
  };

  const openUploadModal = () => {
    showModal(ModalTypes.COMMON, {
      title: "Upload youtube content",
      body: <AddYoutubeVideoForm onSubmit={uploadVideo} />,
    });
  };

  const openEditModal = () => {
    showModal(ModalTypes.COMMON, {
      title: "Edit youtube content",
      body: (
        <AddYoutubeVideoForm
          defaultValues={itemSelected}
          onSubmit={(a: any) => Promise.resolve()}
        />
      ),
    });
  };

  return (
    <VStack py="8" px="6" space="6" w="100%">
      <HStack justifyContent="space-between" alignItems="center" zIndex={1}>
        <SectionHeader title={tVideos("title")} />
        <HStack space="2">
          <Button
            py="3"
            px="6"
            bg="primary.400"
            rounded="xl"
            onClick={openUploadModal}
          >
            <HStack alignItems="center" space="2">
              <IconPlus size={16} color="white" />
              <Text color="white">{t("messages.upload")}</Text>
            </HStack>
          </Button>
        </HStack>
      </HStack>
      <Table
        columns={columns}
        fetcher={mapToTableData(getVideosApi)}
        url={GET_VIDEOS_URL}
      />
    </VStack>
  );
};
