import {
  CommonSection,
  TextField,
  TextAreaField,
  SelectField,
} from "@components/";
import { useIsMobile, useTranslation, withFormFieldProps } from "@utils/hooks";
import { withFormField } from "@utils/hoc";
import {
  VStack,
  HStack,
  Button,
  Image,
  Text,
  Switch,
  Stack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export interface EditProfileFormProps extends InterfaceBoxProps {}

const TextFieldForm = withFormFieldProps(TextField);
const TextAreaFieldForm = withFormFieldProps(TextAreaField);
const SelectFieldForm = withFormField(SelectField, {
  formContainerProps: { maxW: "200px" },
});

export const EditProfileForm: FC<EditProfileFormProps> = ({ ...props }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation({ keyPrefix: "settings" });
  const { control, handleSubmit } = useForm<any>({
    defaultValues: {
      language: i18n.language,
      name: "",
      lastname: "",
      email: "",
      bio: "",
    },
  });

  const submit = (form: any) => {
    i18n.changeLanguage(form.language);
  };

  const isMobile = useIsMobile();

  return (
    <CommonSection
      title={t("forms.profile.title")}
      nextButton={t("forms.changePassword.submitButton")}
      onNextClick={handleSubmit(submit)}
    >
      <VStack>
        <SelectFieldForm
          form={{ control, name: "language" }}
          label="Language"
          options={[
            { label: "Spanish", value: "es" },
            { label: "English", value: "en" },
            { label: "Russian", value: "ru" },
            { label: "Ukranian", value: "ukr" },
          ]}
        />
        <Stack direction={isMobile ? "column" : "row"} space="2">
          <TextFieldForm
            form={{ control, name: "nombre" }}
            containerProps={{ maxW: "300px" }}
            label="Name"
          />
          <TextFieldForm
            form={{ control, name: "lastname" }}
            containerProps={{ maxW: "300px" }}
            label="Lastname"
          />
          <TextFieldForm
            form={{ control, name: "email" }}
            containerProps={{ maxW: "300px" }}
            label="Email"
          />
        </Stack>
        <TextAreaFieldForm
          form={{ control, name: "bio" }}
          containerProps={{ maxW: "300px" }}
          label="Biography"
        />
      </VStack>
    </CommonSection>
  );
};
