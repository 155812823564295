import { ICommonChartProps } from "@utilsmodels/Chart";
import { useTheme } from "native-base";
import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Task", "Hours per Day"],
  ["Work", 11],
  ["Eat", 2],
  ["Commute", 2],
  ["Watch TV", 2],
  ["Sleep", 7], // CSS-style declaration
];

export function PieChart(props: ICommonChartProps) {
  const theme = useTheme();

  const options = {
    title: "My Daily Activities",
    pieHole: 0.4,
    // pieSize: 333,
    is3D: false,
    color: "black",
    colors: [
      theme.colors.primary[400],
      theme.colors.primary[200],
      theme.colors.primary[600],
    ],
  };
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
