import { PieChart, Widget, WidgetInstanceProps } from "@components";
import { VStack } from "native-base";
import { FC } from "react";

export interface GenderWidgetProps extends WidgetInstanceProps<any> {}

export const GenderWidget: FC<GenderWidgetProps> = ({ ...props }) => {
  const { followers, posts } = { followers: 2313, posts: 123 };
  return (
    <Widget onClick={() => {}} minW="314px" w="42%" {...props}>
      <VStack space="2">
        <PieChart />
      </VStack>
    </Widget>
  );
};
