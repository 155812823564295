import { networkClient, networkClientKeycloak } from "./networkClient";

// export const POST_LIKE_URL = '/likes';
export interface IRefreshTokenApiPayload {
  refreshToken: string;
}
export const postRefreshTokenApi = async (payload: IRefreshTokenApiPayload) => {
  const { data } = await networkClient.post(
    `/realms/anilorak/protocol/openid-connect/token`,
    {
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      refresh_token: payload.refreshToken,
      grant_type: "refresh_token",
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  return data;
};
