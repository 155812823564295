import {
  TextField,
  RadioField,
  TextAreaField,
  UploadButton,
  DatePickerField,
  SelectField,
} from "@components";
import { FormWizardField, useFormWizard } from "@contexts/";
import { withFormFieldProps } from "@utils/hooks";
import { withFormField } from "@utils/hoc";
import { Box, HStack } from "native-base";
import React, { FC, ReactNode, useCallback, useEffect } from "react";

const TextFieldForm = withFormFieldProps(TextField);
const SelectFieldForm = withFormField(SelectField);
const RadioFieldForm = withFormFieldProps(RadioField);
const TextAreaFieldForm = withFormField(TextAreaField);
const NumberFieldForm = withFormFieldProps(UploadButton);
const DatePickerFieldForm = withFormFieldProps(DatePickerField);
const UploadFileFieldFom = withFormFieldProps(TextField);
const UploadFileFieldForm = withFormFieldProps(UploadButton);

export interface WizardFormRenderProps {
  name: string;
  onRenderField?: (field: FormWizardField) => ReactNode;
}

export const WizardFormRender: FC<WizardFormRenderProps> = ({
  name,
  onRenderField,
}) => {
  const {
    changeForm,
    formState,
    currentForm: { fields },
  } = useFormWizard();

  const renderField = useCallback(
    (field: FormWizardField) => {
      const { form: fieldFormProps, type, ...rest } = field;
      if (typeof onRenderField === "function") {
        return onRenderField(field);
      }

      const form = { ...formState, ...fieldFormProps };
      switch (type) {
        case "text": {
          return (
            <TextFieldForm
              containerProps={{ maxW: "340px" }}
              form={form}
              {...rest}
            />
          );
        }
        case "radio": {
          return (
            <RadioFieldForm
              containerProps={{ maxW: "340px" }}
              form={form}
              defaultValue={form.defaultValue}
              name={name}
              {...rest}
            />
          );
        }
        case "options": {
          return (
            <SelectFieldForm
              containerProps={{ maxW: "340px" }}
              form={form}
              defaultValue={form.defaultValue}
              name={name}
              {...(rest as any)}
            />
          );
        }
        case "number": {
          return (
            <TextFieldForm
              containerProps={{ maxW: "340px" }}
              form={form}
              {...rest}
            />
          );
        }
        case "date": {
          return (
            <DatePickerFieldForm
              containerProps={{ maxW: "340px" }}
              form={form}
              {...rest}
            />
          );
        }
        case "textarea": {
          return (
            <TextAreaFieldForm
              containerProps={{ maxW: "340px" }}
              form={form}
              {...(rest as any)}
            />
          );
        }

        case "file": {
          return (
            <UploadFileFieldForm
              form={form}
              {...rest}
              containerProps={{ zIndex: -200 }}
              formContainerProps={{ w: "340px" }}
            />
          );
        }

        default: {
          return (
            <TextFieldForm
              formContainerProps={{ w: "340px" }}
              form={form}
              {...rest}
            />
          );
        }
      }
    },
    [fields, name]
  );

  return (
    <HStack space="2" flexWrap="wrap">
      {fields.map((field, index) => {
        return (
          <React.Fragment key={index}>
            {renderField(field)}
            <Box h="2" />
          </React.Fragment>
        );
      })}
    </HStack>
  );
};
