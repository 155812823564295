import { getPostsApi, GET_POTST_URL } from "@api/";
import {
  ModalTypes,
  Post,
  StaggerButton,
  useCommonLayout,
  Skeleton,
} from "@components";
import { useAuth, useModal } from "@contexts";
import { IconDeviceMobile, IconLayoutDashboard } from "@tabler/icons-react";
import { SupportedFileTypes, useIsMobile } from "@utils/hooks";
import { usePaginatedQuery } from "@utils/hooks/usePaginatedQuery";
import { IPaginationResponse } from "@utilsmodels/PaginationCommonResponse";
import { IPostResponse } from "@utilsmodels/PostResponse";
import { getReelsApi, GET_REELS_URL } from "api/getReelsApi";
import { Box, Divider, HStack, Image, useToast, VStack } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Reel } from "components/Reel";
import { useEntryActions } from "./hooks/useEntryActions";

export interface HomeProps extends InterfaceBoxProps {}

export const Home: FC<HomeProps> = ({ ...props }) => {
  const { setStaticContent } = useCommonLayout();
  const { t, i18n } = useTranslation();
  const isMobile = useIsMobile();
  const { isLoading: isSessionLoading } = useAuth();
  const { data, isLoading } = usePaginatedQuery<IPostResponse>(
    [GET_POTST_URL, `?sort=createdAt,desc`],
    getPostsApi,
    {
      enabled: !isSessionLoading,
    }
  );
  const { data: reels, isLoading: isLoadingReels } =
    usePaginatedQuery<IPostResponse>([GET_REELS_URL], getReelsApi, {
      enabled: !isSessionLoading,
    });

  const {
    remove: removeReel,
    edit: editReel,
    upload: uploadReel,
  } = useEntryActions("reel");
  const {
    remove: removePost,
    edit: editPost,
    upload: uploadPost,
  } = useEntryActions("post");

  const staggerOptions = useMemo(
    () => [
      {
        name: "Post",
        icon: <IconDeviceMobile size={26} color="white" />,
        onClick: () => uploadPost(),
      },
      {
        name: "Reel",
        icon: <IconLayoutDashboard size={26} color="white" />,

        onClick: () => uploadReel(),
      },
    ],
    [uploadReel, uploadPost]
  );

  useEffect(() => {
    setStaticContent(
      <div style={{ pointerEvents: "all" }}>
        <Box
          position="absolute"
          right="8%"
          bottom={isMobile ? "160" : "30px"}
          zIndex={2}
        >
          <StaggerButton options={staggerOptions} />
        </Box>
      </div>
    );
    return () => setStaticContent(<></>);
  }, [setStaticContent]);

  return (
    <VStack>
      <HStack space="4" m="4" overflowX="scroll">
        {reels?.content?.map((reel) => (
          <Reel
            data={reel}
            isLoading={false}
            onEdit={editReel}
            onDelete={removeReel}
          />
        ))}
      </HStack>

      <VStack overflowY="hidden" alignItems="center">
        <VStack space="4">
          {data?.content?.map((post) => (
            <Post
              data={post}
              // data={{
              //   description: "adas",
              //   createdAt: new Date().getDate().toString(),
              //   files: [
              //     {
              //       filePath:
              //         "http://react-responsive-carousel.js.org/assets/3.jpeg",
              //     },
              //     {
              //       filePath:
              //         "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerJoyrides.mp4",
              //     },
              //     {
              //       filePath:
              //         "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4",
              //     },
              //     {
              //       filePath:
              //         "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/Sintel.mp4",
              //     },
              //   ],
              // }}
              isLoading={isLoading}
              // isLoading={false}
              onEdit={editPost}
              onDelete={removePost}
            />
          ))}
        </VStack>
      </VStack>
    </VStack>
  );
};
