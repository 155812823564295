import React, { FC, useEffect, useRef } from "react";
import "react-day-picker/dist/style.css";
import {
  useTheme,
  FormControl,
  Text,
  Radio,
  WarningOutlineIcon,
  VStack,
  Stack,
  HStack,
} from "native-base";
import { IFormField } from "@utilsmodels/FormField";

export interface RadioOption {
  value: string;
  label: string;
}
export interface RadioFieldProps extends IFormField {
  options: RadioOption[];
}

export const RadioField: FC<RadioFieldProps> = ({
  label,
  helperText,
  value,
  name,
  defaultValue,
  errorMessage,
  options,
  containerProps,
  onChange,
}) => {
  const theme = useTheme();

  return (
    <FormControl {...containerProps}>
      <FormControl.Label>{label}</FormControl.Label>
      <Radio.Group
        name={name}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        <HStack space="2">
          {options.map(({ value, label }, index) => (
            <Radio value={value} my="1" key={index}>
              {value}
            </Radio>
          ))}
        </HStack>
      </Radio.Group>
      <FormControl.HelperText>{helperText}</FormControl.HelperText>
      <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
        {errorMessage}
      </FormControl.ErrorMessage>
    </FormControl>
  );
};
