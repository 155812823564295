import { Button as NButton, IButtonProps } from "native-base";
import { ReactNode } from "react";

export const Button = ({
  children,
  onClick,
  ...props
}: {
  children?: ReactNode | ReactNode[];
  type?: string;
  name?: string;
  onClick?: (e: any) => void;
} & IButtonProps) => (
  <button
    style={{
      border: "none",
      outline: "none",
      boxShadow: "none",
      backgroundColor: "transparent",
      // width: "100%",
    }}
    onMouseDown={onClick}
  >
    <NButton {...props}>{children}</NButton>
  </button>
);
