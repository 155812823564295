import { GET_POTST_URL } from "@api*";
import { FormWizardDef, FormWizardProvider } from "@contexts/";
import { useQuery } from "@tanstack/react-query";
import { SupportedFileTypes, useTranslation } from "@utils/hooks";
import { getAlbumsApi, GET_ALBUMS_URL } from "api/getAlbumsApi";
import { format } from "date-fns";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC } from "react";
import { Outlet } from "react-router-dom";

export interface MusicProps extends InterfaceBoxProps {}

export const Music: FC<MusicProps> = ({ ...props }) => {
  return <Outlet />;
};
