import { useAuth } from "@contexts";
import { useKeycloak } from "@react-keycloak/web";
// import keycloak from "keycloak-js";
import { Spinner } from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { useEffect } from "react";
import { FC } from "react";
import { keycloak } from "@utils/services";
export interface LogoutProps extends InterfaceBoxProps {}

export const Logout: FC<any> = ({ ...props }) => {
  // const { keycloak } = useKeycloak();
  const { actions } = useAuth();

  useEffect(() => {
    (async () => {
      await keycloak.logout();
      actions.logout();
    })();
  }, [keycloak, actions]);

  return <Spinner />;
};
