import { FileUpload } from "components/FileUpload";
import { Button, RadioField, SwitchButton } from "@components/";
import {
  Text,
  VStack,
  Box,
  Stack,
  Spinner,
  useToast,
  HStack,
} from "native-base";
import { useEffect, useMemo, useState } from "react";
import { useAuth, useModal } from "@contexts";
import {
  SupportedFileTypes,
  useIsMobile,
  useTranslation,
  withFormFieldProps,
} from "@utils/hooks";
import { useGeolocated } from "react-geolocated";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { FormField } from "components/FormField";
import { IFileDef } from "@utilsmodels/FileItem";
import { ICommonFormProps } from "@utilsmodels/CommonFormProps";
import { IconEdit, IconKey } from "@tabler/icons-react";
import { withFormField } from "@utils/hoc";
import YouTube from "react-youtube";

export interface UserFormProps
  extends ICommonFormProps<{
    title: string;
    url: string;
    [x: string]: any;
  }> {}

const FieldUploadForm = withFormFieldProps(FileUpload);
const SwitchButtonForm = withFormField(SwitchButton);

export const UserForm: React.FC<UserFormProps> = ({
  defaultValues,
  onSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation({ keyPrefix: "commons.messages" });
  const isMobile = useIsMobile();

  const { session } = useAuth();

  // const { hideModal } = useModal();
  // const { show, closeAll } = useToast();
  const { control, handleSubmit, formState, watch } = useForm<any>({
    defaultValues: defaultValues ?? {
      title: "",
      url: "",
      premium: false,
    },
  });

  const submit = async (form: any) => {
    const newForm: any = new FormData();

    newForm.append("userID", `${session?.user.id}`);
    newForm.append("shouldPost", "false");
    newForm.append("description", form.title);
    newForm.append("title", form.title);
    newForm.append("url", form.url);

    setLoading(true);
    onSubmit(newForm)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const urlFieldValue = watch("url");
  const youtubeVideoId = useMemo(() => "", [urlFieldValue]);

  return (
    <Stack
      //   direction={isMobile ? "column" : "row"}
      direction="column"
      px="6"
      py="4"
      space="2"
      //   minH={"420px"}
    >
      <Stack direction={isMobile ? "column" : "row"} space="6">
        <Box w="45%">
          <FormField.Text
            form={{ control, name: "fullname" }}
            label={t("fullname")}
          />
        </Box>

        <Box w="45%">
          <FormField.Text
            form={{ control, name: "email" }}
            label={t("email")}
          />
        </Box>
      </Stack>

      <HStack space="6">
        <Box w="170px">
          <SwitchButtonForm
            form={{ control, name: "premium" }}
            label={t("premium")}
            options={[
              {
                label: t("yes"),
                value: true,
                Icon: IconEdit,
              },
              {
                label: t("no"),
                value: false,
                Icon: IconKey,
              },
            ]}
          />
        </Box>
        <Box w="170px">
          <SwitchButtonForm
            form={{ control, name: "premium" }}
            label={t("premium")}
            options={[
              {
                label: t("yes"),
                value: true,
                Icon: IconEdit,
              },
              {
                label: t("no"),
                value: false,
                Icon: IconKey,
              },
            ]}
          />
        </Box>
      </HStack>
      <HStack justifyContent="flex-end">
        <Button w="100px" onClick={handleSubmit(submit)} isLoading={loading}>
          <Text bold color="white">
            {t("save")}
          </Text>
        </Button>
      </HStack>
    </Stack>
  );
};
