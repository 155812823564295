import React, { FC, InputHTMLAttributes, useEffect, useRef } from "react";
import {
  FormControl,
  IInputProps,
  TextArea,
  Stack,
  WarningOutlineIcon,
  ITextAreaProps,
} from "native-base";

export interface TextAreaProps extends Partial<ITextAreaProps> {}

export const TextAreaField: FC<TextAreaProps> = ({ ...props }) => {
  const propss: any = props;
  return <TextArea bg="#F3F5F7" {...propss} />;
};
