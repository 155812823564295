import {
  Box,
  Center,
  Heading,
  HStack,
  IconButton,
  Pressable,
  Text,
  VStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC, ReactNode, useCallback, useContext, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigation,
  useOutletContext,
} from "react-router-dom";
import {
  IconLogout,
  IconHome,
  IconBrandGoogleAnalytics,
  IconPlaylist,
  IconSettings,
  IconVideo,
  IconNotification,
} from "@tabler/icons-react";
import { Menu } from "@components/Menu";
import { BreadcrumbTrail } from "components/Breadcrumbs/Breadcrumbs";
import useBreadcrumbs, {
  createRoutesFromChildren,
  Route,
} from "use-react-router-breadcrumbs";

export interface SectionHeaderProps {
  title?: string;
}

export const SectionHeader: FC<SectionHeaderProps> = ({ title, ...props }) => {
  const location = useLocation();
  const breadCrumbs = useBreadcrumbs([], { excludePaths: ["/"] });
  return (
    <VStack space="2">
      <HStack justifyContent="space-between" alignItems="center">
        <Heading fontSize="2xl">{title ?? location.pathname} </Heading>
      </HStack>
      <BreadcrumbTrail breadcrumbs={breadCrumbs} />
    </VStack>
  );
};
