import React from "react";

import { addMonths, format } from "date-fns";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  Box,
  Text,
  HStack,
  Divider,
  VStack,
  useDisclose,
  IconButton,
  useTheme,
} from "native-base";
import {
  IconCalendar,
  IconChevronDown,
  IconChevronLeft,
} from "@tabler/icons-react";
import { Button, EmptyButton } from "@components/";

enum DatePickerSelectionType {
  "from",
  "to",
}

export const DatePicker = () => {
  const theme = useTheme();
  const [fromDate, setFromDate] = React.useState<Date>(new Date());
  const [toDate, setToDate] = React.useState<Date>(addMonths(new Date(), 1));
  const [step, setStep] = React.useState<null | DatePickerSelectionType>(null);
  const { isOpen, onOpen, onClose } = useDisclose();
  const { startDate, endDate } = {
    startDate: format(fromDate, "dd/MM"),
    endDate: format(toDate, "dd/MM"),
  };

  const setDate = (date: Date) => {
    console.log("EEE", date);
    if (step === DatePickerSelectionType.from) {
      setFromDate(date);
    } else {
      setToDate(date);
    }
    onClose();
  };

  const openDatePicker = (step: DatePickerSelectionType) => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }

    setStep(step);
  };

  return (
    <VStack space="4">
      <HStack
        w="316px"
        h="56px"
        rounded="lg"
        shadow={2}
        bg="white"
        space="2"
        px="4"
        alignItems="center"
        justifyContent="center"
      >
        <Box>
          <IconCalendar size={26} color="grey" />
        </Box>

        <EmptyButton
          w="80px"
          h="56px"
          onClick={() => openDatePicker(DatePickerSelectionType.from)}
        >
          <Text fontWeight="medium">{startDate}</Text>
        </EmptyButton>

        <Divider orientation="vertical" h="60%" />
        <EmptyButton
          w="80px"
          h="56px"
          onClick={() => openDatePicker(DatePickerSelectionType.to)}
        >
          <Text fontWeight="medium">{endDate}</Text>
        </EmptyButton>

        <Box w="40px">
          {isOpen ? (
            <IconChevronDown size={26} color="grey" />
          ) : (
            <IconChevronLeft size={26} color="grey" />
          )}
        </Box>
      </HStack>

      {isOpen && (
        <Box
          position="absolute"
          zIndex={2000000}
          top="70px"
          bg="white"
          fontFamily="Arial"
          shadow={2}
          rounded="3xl"
        >
          <Button>ASDSAD</Button>
          {/* <DayPicker
            style={{ pointerEvents: "all" }}
            mode="single"
            modifiersStyles={{
              today: { backgroundColor: theme.colors.primary[100] },
              selected: { backgroundColor: theme.colors.primary[400] },
            }}
            selected={fromDate}
            onSelect={(day) => setDate(day ?? new Date())}
          /> */}
        </Box>
      )}
    </VStack>
  );
};
