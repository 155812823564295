import { Button } from "@components";
import { IModalProps } from "@utils/models";
import { Center, HStack, Modal, Text, useDisclose } from "native-base";
import { useTranslation } from "react-i18next";

export interface ConfirmModalProps extends IModalProps {}

export const Confirm: React.FC<ConfirmModalProps> = ({ payload }) => {
  const { t } = useTranslation();
  const { isOpen: isLoading, onOpen, onClose } = useDisclose();

  const submit = async () => {
    onOpen();
    if (payload.onConfirm().then) {
      payload.onConfirm().then(() => onClose());
    } else {
      onClose();
    }
  };
  return (
    <Modal.Content size="sm" height="200px">
      <Modal.CloseButton />
      <Modal.Header>{payload.title}</Modal.Header>
      <Modal.Body>
        <Center>
          <Text>{payload.description}</Text>
        </Center>
      </Modal.Body>
      <Modal.Footer>
        <HStack space="4">
          <Button variant="ghost" onClick={payload.onCancel}>
            {t("commons.modals.confirm.cancelButton")}
          </Button>

          <Button
            // variant="ghost"
            isLoading={isLoading}
            onClick={submit}
          >
            {t("commons.modals.confirm.confirmButton")}
          </Button>
        </HStack>
      </Modal.Footer>
    </Modal.Content>
  );
};
