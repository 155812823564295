import { useIsMobile } from "@utils/hooks";
import { Box, Button, HStack, IStackProps, Stack, VStack } from "native-base";
import { FC } from "react";

export interface IMStackProps extends IStackProps {}

export const MStack: FC<IMStackProps> = ({ children, ...rest }) => {
  const isMobile = useIsMobile();
  return (
    <Stack
      direction={isMobile ? "column" : "row"}
      space="2"
      flexWrap="wrap"
      {...rest}
    >
      {children}
    </Stack>
  );
};
