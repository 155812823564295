// import { useNavigation } from "@react-navigation/native";
import { MapChart, Widget, WidgetInstanceProps } from "@components/";
import { IconDownload, IconSettings } from "@tabler/icons-react";
import {
  Avatar,
  Box,
  Center,
  Divider,
  Heading,
  HStack,
  IconButton,
  Pressable,
  Progress,
  Text,
  VStack,
} from "native-base";
import { InterfaceBoxProps } from "native-base/lib/typescript/components/primitives/Box";
import { FC } from "react";

export interface GeoWidgetProps extends WidgetInstanceProps<any> {}

export const GeoWidget: FC<GeoWidgetProps> = ({ ...props }) => {
  const { followers, posts } = { followers: 2313, posts: 123 };
  return (
    <Widget onClick={() => {}} w="55%" {...props}>
      <VStack space="2">
        <VStack>
          <MapChart />
        </VStack>
      </VStack>
    </Widget>
  );
};
